$body_background_color: #0f1211;
$body_text_color: #fff;


$header_border_color: #002711;


$star_gradient: radial-gradient(58.8% 50% at 50% 50%, rgba(12, 175, 165, .15) 0%, rgba(15, 15, 14, 0) 100%);


$menu_background_color_mobile: #051514;
$menu_color_link: #00fe3f;
$menu_border_color: #00fe3f;
$menu_color_link_hover: #22d148;
$menu_border_color_hover: #22d148;


$slogan_text_color: #00ad4e;

$paysystems_others_color: #979797;

$list_number_background_color: #00ff40;
$list_number_color: #0f0f0f;


$button_general_background_color: #00ff40;
$button_general_background_color_hover: #29ff8d;
$button_general_background_color_active: #00d526;
$button_general_color: #0c0e0d;


$button_solution_background_color_hover: #0b2016;
$button_solution_background_color_active: #0d2e20;
$button_solution_color: #00e03a;


$label_support_background_color: #041912;


$banner_background_color: #041912;


$li_title_color: #008d43;
$li_title_icon_color: #00ff3f;


$form_border_color: #1b412a;
$form_input_border_color: #1b412a;
$form_input_border_color_focus: #35ff61;
$form_input_color: #fff;
$form_input_validate_border_color: #fd3528;

$link_contact_background_color_hover: #162c20;
$link_contact_background_color_active: #1e432f;

$validate_message_background: #fd3528;

$content_title_color: #43ec80;
$content_image_background: #091812;
$content_image_border: #1b412a;
$content_image_shadow: #112C23;

.ofelos-pay-banner {
    box-sizing: border-box;
    border-radius: scale-d(15);
    background: $banner_background_color url('../../../css-images/paysystem-logotypes.svg') no-repeat 100% 0;
    box-shadow: 0 scale-d(33) scale-d(70) #112c23; 
    width: scale-d(1030);
    padding: scale-d(40) scale-d(60);
    margin: 0 auto;
    background-size: scale-d(623);

    @media screen and (max-width: 768px) {
        width: 100%;
        border-radius: 0;
        box-shadow: 0 scale-m(33) scale-m(70) #112c23;         
        padding: scale-m(58) 0 scale-m(40);
        background-size: 150%;
        background-position: -8% 1%;
    }
}

.ofelos-pay-banner-description {
    margin-bottom: scale-d(22);

    @media screen and (max-width: 768px) {
        max-width: scale-m(335);
        margin: 0 auto scale-m(30);
    }

    h2, h3, h4 {
        font-family: 'SF Pro Display Bold';
        font-size: scale-d(32);
        line-height: scale-d(38);
        margin-bottom: scale-d(3);

        @media screen and (max-width: 768px) {
            font-size: scale-m(32);
            line-height: scale-m(38);
            margin-bottom: scale-m(10);
        }
    }

    .text-description {
        font-size: scale-d(16);
        line-height: scale-d(19);

        @media screen and (max-width: 768px) {
            font-size: scale-m(16);
            line-height: scale-m(21);
            max-width: 75%;
        }
    }
}

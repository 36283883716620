.ofelos-pay-form {
    box-sizing: border-box;
    border-radius: scale-d(15);
    width: scale-d(485);
    border: scale-d(1) solid $form_border_color;
    margin: 0 auto scale-d(100);
    padding: scale-d(35) scale-d(45);

    .button-general {
      min-width: 100%;
      text-align: center;
    }

    @media screen and (max-width: 768px) {
        border-radius: scale-m(15);
        width: 100%;
        max-width: scale-m(335);
        margin: 0 auto scale-m(30);
        border-width: scale-m(1);
        padding: scale-m(22) scale-m(25);
    }
}

.communication {
    margin-bottom: scale-d(35);

    @media screen and (max-width: 768px) {
        margin-bottom: scale-m(30);
    }

    .link-contact-go {
        border-radius: scale-d(9);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        height: scale-d(39);
        transition: all .3s ease;

        @media screen and (max-width: 768px) {
            border-radius: scale-m(9);
            height: scale-m(39);
        }

        &.telegram {
            padding: 0 scale-d(10) 0 scale-d(5);

            @media screen and (max-width: 768px) {
                padding: 0 scale-m(10) 0 scale-m(5);
            }
        }

        &.email {
            padding: 0 scale-d(10);

            @media screen and (max-width: 768px) {
                padding: 0 scale-m(10);
            }
        }

        &:hover {

            @media screen and (min-width: 768px) {
                background-color: $link_contact_background_color_hover;
            }
        }

        &:active {
            background-color: $link_contact_background_color_active;
        }
    }

    .row {
        text-align: center;
    }

    .row + .row {
        margin-top: scale-d(10);

        @media screen and (max-width: 768px) {
            margin-top: scale-m(8);
        }
    }

    .icon {
        display: block;
        width: scale-d(29);
        margin-right: scale-d(12);

        @media screen and (max-width: 768px) {
            width: scale-m(30);
            margin-right: scale-m(12);
        }

        img {
            width: 100%;
        }
    }

    .title {
        color: #35ff61;
        font-size: scale-d(22);
        line-height: scale-d(26);
        transition: all ease .3s;

        @media screen and (max-width: 768px) {
            font-size: scale-m(22);
            line-height: scale-m(26);
        }
    }
}

.form-fields {

    .row {
        position: relative;
    }

    .row + .row {
        margin-top: scale-d(15);

        @media screen and (max-width: 768px) {
            margin-top: scale-m(15);
        }
    }

    .row:last-child {
        text-align: center;
    }
}

input,
textarea {

    &::placeholder {
        color: #fff;
        transition: color ease .2s;
    }

    &:focus {

        &::placeholder {
            color: transparent;
        }
    }
}

.form-input {
    box-sizing: border-box;
    border-radius: scale-d(15);
    background-color: transparent;
    border: scale-d(1) solid $form_input_border_color;
    width: 100%;
    height: scale-d(51);
	font-family: 'Gotham Book';
    font-size: scale-d(18);
    line-height: scale-d(19);
    color: $form_input_color;
    padding: 0 scale-d(20);
    box-shadow: none;
    caret-color: #fff;

    @media screen and (max-width: 768px) {
        border-radius: scale-m(15);
        border-width: scale-m(1);
        height: scale-m(51);
        font-size: scale-m(16);
        line-height: scale-m(19);
        padding: 0 scale-m(17);
    }

    &:focus {
        border-color: $form_input_border_color_focus;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background-color: #142322 !important;
        -webkit-text-fill-color: #fff;
        color: #fff;
        -webkit-box-shadow: inset 0 0 0 scale-d(70) #142322 !important;

        @media screen and (max-width: 768px) {
            -webkit-box-shadow: inset 0 0 0 scale-m(70) #142322 !important;
        }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .validate-active & {
        border-color: $form_input_validate_border_color;
    }
}

.form-textarea {
    box-sizing: border-box;
    border-radius: scale-d(15);
    background-color: transparent;
    border: scale-d(1) solid $form_input_border_color;
    width: 100%;
    height: scale-d(126);
	font-family: 'Gotham Book';
    font-size: scale-d(18);
    line-height: scale-d(19);
    color: $form_input_color;
    padding: scale-d(15) scale-d(20);
    box-shadow: none;
    resize: none;
    caret-color: #fff;

    @media screen and (max-width: 768px) {
        border-radius: scale-m(15);
        border-width: scale-m(1);
        height: scale-m(126);
        font-size: scale-m(16);
        line-height: scale-m(19);
        padding: scale-m(16) scale-m(17);
    }

    &:focus {
        border-color: $form_input_border_color_focus;
    }

    .validate-active & {
        border-color: $form_input_validate_border_color;
    }
}

.validate-field {
    display: none;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    height: scale-d(51);
    padding: 0 scale-d(20) 0 0;
    right: 0;
    top: 0;
    z-index: 1;

    &:hover {

        .message-field {

            @media screen and (min-width: 768px) {
                opacity: 1;
            }
        }
    }

    .validate-active & {
        display: flex;
    }

    @media screen and (max-width: 768px) {
        height: scale-m(51);
        padding: 0 scale-m(20) 0 0;
    }

    .round-icon {
        display: block;
        width: scale-d(19);
        height: scale-d(19);
        background: url('../../../css-images/validate-icon.svg') no-repeat;
        background-size: 100%;

        @media screen and (max-width: 768px) {
            width: scale-m(19);
            height: scale-m(19);
        }
    }

    .message-field {
        opacity: 0;
        transition: all .5s ease;
    }

    input:focus + &,
    textarea:focus + & {

        .message-field {
            opacity: 1;
        }
    }
    
}


.message-field {
    display: inline-block;
    border-radius: scale-d(4);
    background-color: $validate_message_background;
    color: #fff;
    font-size: scale-d(13);
    line-height: scale-d(15);
    padding: scale-d(4) scale-d(12) scale-d(4) scale-d(10);
    position: absolute;
    white-space: nowrap;
    right: scale-d(13);
    top: scale-d(-20);

    &::after {
        content: '';
        display: block;
        width: 0;
        border-left: scale-d(7) solid transparent;
        border-right: scale-d(7) solid transparent;
        border-top: scale-d(8) solid $validate_message_background;
        position: absolute;
        right: scale-d(10);
        bottom: scale-d(-7);
        z-index: 1;

        @media screen and (max-width: 768px) {
            border-left: scale-m(7) solid transparent;
            border-right: scale-m(7) solid transparent;
            border-top: scale-m(8) solid $validate_message_background;
            right: scale-m(10);
            bottom: scale-m(-7);
        }
    }

    @media screen and (max-width: 768px) {
        border-radius: scale-m(4);
        font-size: scale-m(13);
        line-height: scale-m(15);
        padding: scale-m(4) scale-m(12) scale-m(4) scale-m(10);
        right: scale-m(13);
        top: scale-m(-20);
    }
}

body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $body_background_color url('../../../css-images/bg-world.png') no-repeat;
    background-size: 100%;
    color: $body_text_color;
	font-family: 'Gotham Book';

    @media screen and (max-width: 768px) {
        background: $body_background_color url('../../../css-images/bg-world-mobile.png') no-repeat;
        background-size: 100%;
    }

    &.stop-scroll {

        @media screen and (max-width: 768px) {
            max-height: 100%;
            overflow: hidden;
        }
    }
}

#root, .App-landing {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

button,
a {
    -webkit-tap-highlight-color: transparent;
}

.App-landing {
    position: relative;
    overflow: hidden;
}

.ofelos-pay-inner {
    max-width: scale-d(1150);
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        max-width: scale-m(335);
    }
}

.ofelos-pay-section {

    &.second {
        position: relative;
        padding-top: scale-d(260);
        
        @media screen and (max-width: 768px) {
            padding-top: scale-m(77);
        }
    }

    &.third {
        padding-top: scale-d(80);

        @media screen and (max-width: 768px) {
            padding-top: scale-m(86);
        }
    }

    &.fourth {
        position: relative;
        overflow: hidden;
        padding-top: scale-d(60);
        padding-bottom: scale-d(53);

        @media screen and (max-width: 768px) {
            padding-top: scale-m(40);
            padding-bottom: scale-m(40);
        }
    }

    &.fifth {
        background: linear-gradient(180deg, #06130E 0%, rgba(9, 24, 18, 0) 100%);
        padding-top: scale-d(42);

        @media screen and (max-width: 768px) {
            padding-top: scale-m(40);
        }
    }

    &.sixth {
        position: relative;
        padding-top: scale-d(70);

        @media screen and (max-width: 768px) {
            padding-top: scale-m(40);
        }
    }
}

.ofelos-pay-section-title {
    margin-bottom: scale-d(45);
    text-align: center;

    @media screen and (max-width: 768px) {
        margin-bottom: scale-m(54);
    }

    h1, h2, h3, h4 {
        font-family: 'SF Pro Display Bold';
        font-size: scale-d(32);
        line-height: scale-d(38);

        @media screen and (max-width: 768px) {
            font-size: scale-m(32);
            line-height: scale-m(38);
        }
    }

    .fourth &,
    .fifth &,
    .sixth & {

        @media screen and (max-width: 768px) {
            width: inherit;
            max-width: scale-m(335);
            margin: 0 auto scale-m(25);
            text-align: center;
        }
    }
}

.ofelos-pay-footer {
    margin-top: auto;
}

.ofelos-pay-more {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        max-width: scale-m(335);
        margin: 0 auto;
    }

    &.left {
        justify-content: flex-start;
    }
}

.button-general {
    background-color: $button_general_background_color;
    border-radius: scale-d(15);
    display: inline-block;
    height: scale-d(56);
    line-height: scale-d(56);
    padding: 0 scale-d(65);
	font-family: 'Gotham Bold';
    font-size: scale-d(20);
    color: $button_general_color;
    transition: all ease .3s;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        border-radius: scale-m(15);
        width: 100%;
        height: scale-m(54);
        line-height: scale-m(54);
        padding: 0 scale-m(15);
        font-size: scale-m(20);
        text-align: center;
    }

    &:hover {

        @media screen and (min-width: 768px) {
            background-color: $button_general_background_color_hover;
        }
    }

    &:active {
        background-color: $button_general_background_color_active;
    }
}

.button-solution {
    position: relative;
    background-color: inherit;
    border-radius: scale-d(15);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: scale-d(245);
    height: scale-d(66);
    padding: scale-d(8);
    font-size: scale-d(16);
    line-height: scale-d(19);
    color: $button_solution_color;
    left: scale-d(-8);
    text-decoration: none;
    transition: all ease .3s;
        
    @media screen and (max-width: 768px) {
        border-radius: scale-m(15);
        width: scale-m(248);
        height: scale-m(66);
        padding: scale-m(8);
        font-size: scale-m(16);
        line-height: scale-m(19);
        left: scale-m(-8);
    }

    &:hover {

        @media screen and (min-width: 768px) {
            background-color: $button_solution_background_color_hover;
        }
    }

    &:active {
        background-color: $button_solution_background_color_active;
    }

    .icon {
        $wh: scale-d(50);
        display: block;
        width: $wh;
        height: $wh;
        margin-right: scale-d(15);
        flex-shrink: 0;
        
        @media screen and (max-width: 768px) {
            $wh: scale-m(50);
            display: block;
            width: $wh;
            height: $wh;
            margin-right: scale-m(15);
        }
    
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.label-support {
    box-sizing: border-box;
    background-color: $label_support_background_color;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: scale-d(15);
    width: scale-d(304);
    height: scale-d(78);
    padding: scale-d(27) scale-d(30);
    font-size: scale-d(20);
    line-height: scale-d(24);

    @media screen and (max-width: 768px) {
        border-radius: scale-m(15);
        width: 100%;
        height: scale-m(66);
        padding: 0 scale-m(15);
        font-size: scale-m(20);
        line-height: scale-m(24);
        justify-content: center;
    }

    .icon {
        display: block;
        width: scale-d(25);
        margin-right: scale-d(15);

        @media screen and (max-width: 768px) {
            width: scale-m(28);
            margin-right: scale-m(15);
        }
        
        img {
            width: 100%;
        }
    }
}

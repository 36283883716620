@font-face {
	font-family: 'Gotham Book';
	src: url('../fonts/Gotham/Gotham-Book.eot');
	src: local('../fonts/Gotham/Gotham-Book'), local('Gotham-Book'),
		url('../fonts/Gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Book.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Book.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Medium';
	src: url('../fonts/Gotham/Gotham-Medium.eot');
	src: local('../fonts/Gotham/Gotham-Medium'), local('Gotham-Medium'),
		url('../fonts/Gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Medium.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Medium.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Bold';
	src: url('../fonts/Gotham/Gotham-Bold.eot');
	src: local('../fonts/Gotham/Gotham-Bold'), local('Gotham-Bold'),
		url('../fonts/Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gotham/Gotham-Bold.woff') format('woff'),
		url('../fonts/Gotham/Gotham-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Display Bold';
	src: url('../fonts/SF-Display/SFProDisplay-Bold.eot');
	src: local('../fonts/SF-Display/SFProDisplay-Bold'), local('SF Pro Display Bold'),
		url('../fonts/SF-Display/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SF-Display/SFProDisplay-Bold.woff') format('woff'),
		url('../fonts/SF-Display/SFProDisplay-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

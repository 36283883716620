.ofelos-pay-ul {
    max-width: scale-d(700);
    margin: 0 auto scale-d(60);
    
    @media screen and (max-width: 768px) {
        max-width: scale-m(335);
        margin: 0 auto scale-m(60);
    }

    li {
        display: flex;
        font-size: scale-d(20);
        line-height: scale-d(24);
        margin-left: scale-d(-70);

        @media screen and (max-width: 768px) {
            display: block;
            font-size: scale-m(18);
            line-height: scale-m(21);
            margin-left: 0;
        }
    }

    li + li {
        margin-top: scale-d(30);

        @media screen and (max-width: 768px) {
            margin-top: scale-m(15);
        }
    }

    .li-title {
        color: $li_title_color;
        width: 50%;
        text-align: right;
        margin-right: scale-d(20);
        white-space: nowrap;

        @media screen and (max-width: 768px) {
            margin-right: 0;
            width: 100%;
            text-align: left;
            margin-bottom: scale-m(15);
        }
    }

    .li-text {
        width: 80%;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        p + p {
            margin-top: scale-d(7);

            @media screen and (max-width: 768px) {
                margin-top: scale-m(5);
            }
        }
    }

    b {
        font-family: 'Gotham Bold';
    }
}

.ofelos-pay-ul-text-icons {
    margin-top: scale-d(17);

    @media screen and (max-width: 768px) {
        margin-top: scale-m(15);
    }

    li {
        display: flex;
        align-items: center;
    }

    li + li {
        margin-top: scale-d(14);

        @media screen and (max-width: 768px) {
            margin-top: scale-m(15);
        }
    }

    .icon {
        display: block;
        width: scale-d(30);
        margin-right: scale-d(15);
        flex-shrink: 0;

        @media screen and (max-width: 768px) {
            width: scale-m(30);
            margin-right: scale-m(15);
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        color: $li_title_icon_color;
        font-size: scale-d(16);
        line-height: scale-d(19);

        @media screen and (max-width: 768px) {
            font-size: scale-m(16);
            line-height: scale-m(19);
        }
    }
}

.ofelos-pay-copyright {
    text-align: center;
    font-size: scale-d(14);
    line-height: scale-d(17);
    padding-bottom: scale-d(30);
    color: #45544a;

    @media screen and (max-width: 768px) {
        font-size: scale-m(14);
        line-height: scale-m(17);
        padding-bottom: scale-m(30);
    }
}

.ofelos-pay-privacy-policy {
    margin-bottom: scale-d(12);

    a {
        color: #00fe3f;
        margin: 0 scale-d(10);

        @media screen and (max-width: 768px) {
            margin: 0 scale-m(10);
        }
    }

    @media screen and (max-width: 768px) {
        margin-bottom: scale-m(12);
    }
}

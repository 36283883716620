.anchor-section {
    position: relative;

    .second & {
        top: scale-d(-120);

        @media screen and (max-width: 768px) {
            top: scale-m(-60);
        }
    }

    .third & {
        top: scale-d(-50);

        @media screen and (max-width: 768px) {
            top: scale-m(-30);
        }
    }

    .fifth & {
        top: scale-d(-50);

        @media screen and (max-width: 768px) {
            top: scale-m(-30);
        }
    }
}

.ofelos-pay-header {
    border-bottom: scale-d(1) solid $header_border_color;

    @media screen and (max-width: 768px) {
        border-width: scale-m(1);
    }

    .ofelos-pay-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: scale-d(87);

        @media screen and (max-width: 768px) {
            height: scale-m(60);
        }
    }
}

.ofelos-pay-logotype {
    cursor: pointer;
    width: scale-d(153);
    transition: opacity ease .1s;

    @media screen and (max-width: 768px) {
        width: scale-m(122);
        margin: 0 auto;
    }

    img {
        width: 100%;
    }

    &:hover {

        @media screen and (min-width: 768px) {
            opacity: .8;
        }
    }

    &:active {
        opacity: .4;
    }
}

.element-background {
    display: block;
    position: absolute;
    top: scale-d(90);
    right: scale-d(380);
    width: scale-d(436);
    height: 0;
    padding-top: scale-d(734);
    background: url('../../../css-images/bg-hand.svg') no-repeat;
    background-size: contain;
    transform: rotate(14.4deg);
    z-index: -1;

    @media screen and (max-width: 768px) {
        background: url('../../../css-images/bg-hand-mobile.png') no-repeat;
        background-size: contain;
        width: scale-m(294);
        padding-top: scale-m(495);
        top: scale-m(145);
        right: scale-m(-30);
    }
}

.ofelos-pay-menu-mobile {
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: scale-m(60);
    height: scale-m(60);
    z-index: 5;

    @media screen and (max-width: 768px) {
        display: flex;
    }
}

.button-open-menu {
    position: relative;
    $wh: scale-m(24);
    width: $wh;
    height: $wh;

    &:active {
        opacity: .5;
    }

    img {
        position: absolute;
        top: 0;
        width: 100%;
        transition: all ease .3s;
    }

    .close-svg {
        opacity: 0;
    }

    &.active {
        
        .open-svg {
            opacity: 0;
        }

        .close-svg {
            opacity: 1;
        }    
    }
}

.ofelos-pay-menu {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        opacity: 0;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        visibility: hidden;
        background-color: $menu_background_color_mobile;
        padding: scale-m(105) scale-m(28);
        transition: opacity .3s, visibility .75s, width .3s;
        overflow: hidden;
        z-index: 3;
    }

    &.active {

        @media screen and (max-width: 768px) {
            opacity: 1;
            visibility: visible;
            width: 100vw;
        }
    }

    &__item {
        position: relative;
        top: scale-d(3);
        font-family: 'Gotham Medium';
        font-size: scale-d(14);
        line-height: scale-d(16);
        letter-spacing: scale-d(1);
        color: $menu_color_link;
        text-decoration: none;
        text-transform: uppercase;
        border-bottom: scale-d(2) solid transparent;
        padding-bottom: scale-d(4);
        transition: all ease .3s;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            font-size: scale-m(16);
            line-height: scale-m(19);
            letter-spacing: scale-m(1);
            white-space: nowrap;
        }

        &:hover {

            @media screen and (min-width: 768px) {
                border-color: $menu_border_color;
            }
        }

        &:active {
            border-color: $menu_border_color_hover;
            color: $menu_color_link_hover;
        }
    }

    &__item + &__item {
        margin-left: scale-d(50);

        @media screen and (max-width: 768px) {
            margin-left: 0;
            margin-top: scale-m(30);
        }
    }
}

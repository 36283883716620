.ofelos-pay-about {
    width: scale-d(615);
    padding-top: scale-d(114);

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-top: scale-m(66);
    }

    .slogan {
        color: $slogan_text_color;
        font-size: scale-d(22);
        line-height: scale-d(26);
        margin-bottom: scale-d(30);

        @media screen and (max-width: 768px) {
            font-size: scale-m(22);
            line-height: scale-m(26);
            margin-bottom: scale-m(30);
        }
    }

    .title {
        margin-bottom: scale-d(30);

        @media screen and (max-width: 768px) {
            margin-bottom: scale-m(30);
        }

        h1 {
            font-family: 'SF Pro Display Bold';
            font-size: scale-d(42);
            line-height: scale-d(50);

            @media screen and (max-width: 768px) {
                font-size: scale-m(32);
                line-height: scale-m(38);
                max-width: 85%;
            }

            br {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
}

.ofelos-pay-list-paysystems {
    display: flex;
    align-items: center;
    margin-bottom: scale-d(100);

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-bottom: scale-m(100);
    }

    .item {
        display: flex;
        align-items: center;
        height: scale-d(47);

        @media screen and (max-width: 768px) {
            height: scale-m(47);
        }
    
        img {
            height: 100%;
        }
    }

    .item + .item {
        margin-left: scale-d(20);

        @media screen and (max-width: 768px) {
            margin-left: scale-m(15);
        }
    }

    .item:last-child {
        margin-left: scale-d(30);

        @media screen and (max-width: 768px) {
            margin-left: 0;
            height: inherit;
        }
    }

    .other {
        color: $paysystems_others_color;
        font-size: scale-d(24);
        line-height: scale-d(28);

        @media screen and (max-width: 768px) {
            font-size: scale-m(24);
            line-height: scale-m(28);
        }
    }
}

.ofelos-pay-list-items {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
    }

    &.partners {
        margin-bottom: scale-d(37);

        @media screen and (max-width: 768px) {
            margin-bottom: scale-m(80);
        }
    
        .item {
            width: scale-d(250);
            margin: 0 scale-d(40);

            @media screen and (max-width: 768px) {
                width: 100%;
                margin: 0;
            }
        }

        .item + .item {

            @media screen and (max-width: 768px) {
                margin-top: scale-m(45);
            }
        }

        .item-icon {
            display: block;
            width: scale-d(36);
            margin-bottom: scale-d(20);

            @media screen and (max-width: 768px) {
                width: scale-m(36);
                margin-bottom: scale-m(21);
            }

            img {
                width: 100%;
            }
        }
    
        .description {
            font-size: scale-d(16);
            line-height: scale-d(19);

            @media screen and (max-width: 768px) {
                font-size: scale-m(16);
                line-height: scale-m(21);
                max-width: 75%;
            }
        }

        .list-icons {
            display: flex;
            height: scale-d(36);
            margin-bottom: scale-d(20);

            @media screen and (max-width: 768px) {
                height: inherit;
                margin-bottom: scale-m(14);
            }

            .icon {
                position: relative;
                top: scale-d(-5);
                height: scale-d(47);

                @media screen and (max-width: 768px) {
                    top: 0;
                    height: scale-m(47);
                }

                img {
                    height: 100%;
                }
            }
    
            .icon + .icon {
                margin-left: scale-d(14);

                @media screen and (max-width: 768px) {
                    margin-left: scale-m(15);
                }
            }
        }
    }

    &.solution {
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            justify-content: flex-start;
            max-width: scale-m(335);
            margin: 0 auto;
        }

        .item {
            display: flex;
            justify-content: center;
            width: 33.33%;
            margin: 0 scale-d(-30);

            @media screen and (max-width: 768px) {
                justify-content: flex-start;
                width: 100%;
                margin: 0;
            }
        }

        .item + .item {

            @media screen and (max-width: 768px) {
                margin-top: scale-m(8);
            }
        }
    }

    &.principle-of-work {
        align-items: flex-start;

        @media screen and (max-width: 768px) {
            max-width: scale-m(335);
            margin: 0 auto;    
        }

        .item {
            box-sizing: border-box;
            border-radius: scale-d(15);
            width: scale-d(300);
            margin: 0 scale-d(17.5);
            padding: scale-d(20) scale-d(30);
            border: scale-d(1) solid #1b412a;

            @media screen and (max-width: 768px) {
                border-radius: scale-m(15);
                width: 100%;
                margin: 0;
                padding: scale-m(20) scale-m(30);
                border-width: scale-m(1);
            }
        }

        .item + .item {

            @media screen and (max-width: 768px) {
                margin-top: scale-m(15);
            }
        }

        .number {
            $wh: scale-d(50);
            display: block;
            border-radius: scale-d(10);
            background-color: $list_number_background_color;
            color: $list_number_color;
            width: $wh;
            height: $wh;
            line-height: $wh;
            font-family: 'SF Pro Display Bold';
            font-size: scale-d(28);
            margin-bottom: scale-d(20);
            text-align: center;

            @media screen and (max-width: 768px) {
                $wh: scale-m(50);
                border-radius: scale-m(10);
                width: $wh;
                height: $wh;
                line-height: $wh;
                font-size: scale-m(28);
                margin-bottom: scale-m(20);
            }

        }

        .description {
            font-size: scale-d(16);
            line-height: scale-d(19);

            @media screen and (max-width: 768px) {
                font-size: scale-m(16);
                line-height: scale-m(21);
            }

            p + p {
                margin-top: scale-d(20);

                @media screen and (max-width: 768px) {
                    margin-top: scale-m(16);
                }
            }
        }
    }
}

.ofelos-pay-section {

    &.image-and-text {
        padding-top: scale-d(65);
        padding-bottom: scale-d(50);

        @media screen and (max-width: 768px) {
            padding-top: scale-m(20);
            padding-bottom: scale-m(65);
        }

        .ofelos-pay-section-title {

            @media screen and (max-width: 768px) {
                margin-bottom: scale-m(20);
            }
        }
    }
}

.ofelos-content-description {
    display: flex;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .content-image {
        position: relative;
        background-color: $content_image_background;
        border-radius: scale-d(15);
        $wh: scale-d(365);
        width: $wh;
        height: $wh;
        border: scale-d(1) solid $content_image_border;
        margin-right: scale-d(50);
        box-shadow: 0 scale-d(33) scale-d(70) $content_image_shadow;
        flex-shrink: 0;
        overflow: hidden;

        img {
            width: 100%;

            @media screen and (max-width: 768px) {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }

        @media screen and (max-width: 768px) {
            border-radius: scale-m(15);
            width: 100%;
            height: 0;
            padding-top: 100%;
            border-width: scale-m(1);
            margin-right: scale-m(0);
            margin-bottom: scale-m(30);
            box-shadow: 0 scale-m(30) scale-m(62) $content_image_shadow;
        }
    
    }

    .content-text {

        h2 {
            font-family: 'SF Pro Display Bold';
            font-size: scale-d(23);
            line-height: scale-d(27);
            color: $content_title_color;
            margin-bottom: scale-d(25);
            max-width: scale-d(565);

            @media screen and (max-width: 768px) {
                font-size: scale-m(23);
                line-height: scale-m(27);
                margin-bottom: scale-m(25);
                max-width: 100%;
            }
        }

        .description {
            font-size: scale-d(16);
            line-height: scale-d(21);
            max-width: scale-d(850);

            @media screen and (max-width: 768px) {
                font-size: scale-m(16);
                line-height: scale-m(21);
                max-width: 100%;
            }

            a {
                color: #00fe3f;
            }

            ol {
                margin: scale-d(20) 0;

                @media screen and (max-width: 768px) {
                    margin: scale-m(20) 0;
                }

                li {
                    list-style: square inside;
                }

                li ~ li {
                    margin-top: scale-d(10);

                    @media screen and (max-width: 768px) {
                        margin-top: scale-m(10);
                    }
                }
            }
            
            p + p {
                margin-top: scale-d(15);

                @media screen and (max-width: 768px) {
                    margin-top: scale-m(15);
                }
            }
        }
    }

    .content-image + .content-text {

        .description {
            max-width: scale-d(710);

            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
        }
    }
}

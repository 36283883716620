.star-element {
    display: block;
    position: absolute;
    background: $star_gradient;
    width: 100%;
    height: scale-d(1520);
    left: 0;
    z-index: -1;

    @media screen and (max-width: 768px) {
        width: scale-m(753);
        height: scale-m(753);
    }

    .second & {
        bottom: scale-d(-710);

        @media screen and (max-width: 768px) {
            left: scale-m(-267);
            bottom: scale-m(-57);
        }    
    }

    .fourth & {
        bottom: scale-d(-850);

        @media screen and (max-width: 768px) {
            bottom: scale-m(-170);
            left: scale-m(-78);
        }
    }

    .sixth & {
        bottom: scale-d(-580);

        @media screen and (max-width: 768px) {
            bottom: scale-m(-183);
            left: scale-m(-191);
        }
    }
}
